import store from '@/store';
import { VuexModule, Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import { DateTime } from 'luxon';
import { getConfigEnv, jsonParse, sleep } from '@/utils/helpers';
import { decodeGorillaCompression } from '@/utils/testGorillaDecode.js';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import getNodeDescriptionCombinations from '@/graphql/cleanData/queries/getNodeDescriptionCombinations.graphql';
import getCleanTSDataTimeRangeV2 from '@/graphql/cleanData/queries/getCleanTSDataTimeRangeV2.graphql';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import axiosClient from '@/lib/rest/axiosClient';
import axios from 'axios';
import userPreferenceDB from '@/lib/userPreference';
import { to } from 'mathjs';


@Module({
  dynamic: true,
  namespaced: false,
  name: 'tasqSignalsModule',
  store,
})
class TasqSignalsModule extends VuexModule {
  currentSignals: any = [];

  currentPlungerSignals: any = [];

  selectedSignals: any = [];

  signalHighlightStart: any = null;

  signalLabelingModal: any = false;

   abortControllerSingals = null;

   cancelTokenSource = null;

   cancelTokenPlungerSource = null;

   abortControllerPlunger = null;

  signalHighlightEnd: any = null;

  selectedPlungerSignals: any = [];

  editableCurrentSignals: any = [];

  editableCurrentPlungerSignals: any = [];

  chartIQSignals: any = [];

  signalDescriptions: any = [];

  plungerSignalDescriptions: any = [];

  didLoadAllSignals: boolean = false;

  didLoadAllPlungerSignals: boolean = false;

  signalChartTime = 60;

  defaultSelectedSignals:any = []

  defaultSelectedPlungerSignals:any = []

  @Mutation
  resetModule(): void {
    this.currentSignals = [];
    this.currentPlungerSignals = [];

    this.selectedSignals = [];

    this.selectedPlungerSignals = [];

    this.editableCurrentSignals = [];

    this.editableCurrentPlungerSignals = [];

    this.chartIQSignals = [];

    this.signalDescriptions = [];

    this.plungerSignalDescriptions = [];

    this.didLoadAllSignals = false;

    this.didLoadAllPlungerSignals = false;
  }
  isAllData = false

  @Mutation
  setAllData(data): void {
    this.isAllData = data;
  }


  @Mutation
  setSignalLabelingModal(value){
    this.signalLabelingModal = value;
  }



  @Mutation
  setDefaultSignals({type,values}): void {
    this[type] = values;
  }

  @Mutation
  resetSignals(): void {
    // console.log('I am resetting signals');
    this.currentSignals = [];
  }

  @Mutation
  resetPlungerSignals(): void {
    this.currentPlungerSignals = [];
  }


  @Mutation
  setSignalHighlightStart(date): void {
    this.signalHighlightStart = date;
  }


  @Mutation
  setSignalHighlightEnd(date): void {
    this.signalHighlightEnd = date;
  }


  signalHighlightEndLocationIndex: any = null
  signalHighlightStartLocationIndex: any = null


  @Mutation
  setSignalHighlightEndIndex(date): void {
    this.signalHighlightEndLocationIndex = date;
  }


  @Mutation
  setSignalHighlightStartIndex(date): void {
    this.signalHighlightStartLocationIndex = date;
  }


  searchSignalsValues: any = null

  @Mutation
  setSearchSignalValues(date): void {
    console.log(date);
    this.searchSignalsValues = date;
  }







  @Mutation
  resetSignalHighlight(): void {
    this.signalHighlightEnd = null;
    this.signalHighlightStart = null;
  }



  @Mutation
  resetSelectedSignals(): void {
    this.selectedSignals = [];
  }

  @Mutation
  setSelectedSignals(data): void {
    this.selectedSignals = data;
  }

  @Mutation
  resetSelectedPlungerSignals(): void {
    this.selectedPlungerSignals = [];
  }

  @Mutation
  setSelectedPlungerSignals(data): void {
    this.selectedPlungerSignals = data;
  }

  @Mutation
  setSignalChartTime(data): void {
    this.signalChartTime = data;
  }

  @Mutation
  selectSignal(signal_name): void {
    if (this.selectedSignals.indexOf(signal_name) > -1) {
      this.selectedSignals.splice(this.selectedSignals.indexOf(signal_name), 1);
    } else {
      this.selectedSignals.push(signal_name);
    }
  }

  @Mutation
  selectPlungerSignal(signal_name): void {
    if (this.selectedPlungerSignals.indexOf(signal_name) > -1) {
      this.selectedPlungerSignals.splice(this.selectedPlungerSignals.indexOf(signal_name), 1);
    } else {
      this.selectedPlungerSignals.push(signal_name);
    }
  }

  @Mutation
  setDidLoadAllSignals(val): void {
    this.didLoadAllSignals = val;
  }

  @Mutation
  setDidLoadAllPlungerSignals(val): void {
    this.didLoadAllPlungerSignals = val;
  }

  @Mutation
  setSignalDescriptions(descriptions): void {
    this.signalDescriptions = descriptions;
  }

  @Mutation
  setPlungerSignalDescriptions(descriptions): void {
    this.plungerSignalDescriptions = descriptions;
  }

  @Mutation
  addPlungerSignal(signal): void {
    const currentSignalsNames = this.currentPlungerSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentPlungerSignals.push(signal);
  }

  @Mutation
  addSignal(signal): void {
    const currentSignalsNames = this.currentSignals.map((s) => s.name);
    if (currentSignalsNames.includes(signal.name)) return;
    this.currentSignals.push(signal);
  }

  // @Mutation
  // addEditableCurrentSignals(signal): void {
  //   const currentSignalsNames = this.editableCurrentSignals.map((s) => s.name);
  //   if (currentSignalsNames.includes(signal.name)) return;
  //   this.editableCurrentSignals.push(signal);
  // }

  @Mutation
  addChartIQSignal(signal): void {
    // const signalDataExist = this.chartIQSignals.find((s) => s.name);

    this.chartIQSignals.push(signal);
  }

  @Mutation
  setCurrentSignal(data): void {
    this.currentSignals = data;
  }

  @Mutation
  setSignalController(value): void {
    if(value === 'null'){
      this.abortControllerSingals = null
    }else if(value=== 'set') {
      this.abortControllerSingals = new AbortController();
    }else if(this.abortControllerSingals  && value === 'abort'){
      console.log('abortControllerSingals was aborted');
      this.abortControllerSingals.abort()
    }
  }


  @Mutation
  setAxiosSignalController(value): void {
    if(value === 'null'){
      this.cancelTokenSource = null
    }else if(value=== 'set') {
      this.cancelTokenSource =  axios.CancelToken.source();
    }else if(this.cancelTokenSource  && value === 'abort'){
      console.log('abortControllerSingals was aborted');
      this.cancelTokenSource.cancel("Canceling previous request due to new request.");
    }
  }

  @Mutation
  setAxiosPlungerController(value): void {
    if(value === 'null'){
      this.cancelTokenPlungerSource = null
    }else if(value=== 'set') {
      this.cancelTokenPlungerSource =  axios.CancelToken.source();
    }else if(this.cancelTokenPlungerSource  && value === 'abort'){
      console.log('abortControllerSingals was aborted');
      this.cancelTokenPlungerSource.cancel("Canceling previous request due to new request.");
    }
  }

  @Mutation
  setPlungerController(value): void {
    if(value === 'null'){
      this.abortControllerPlunger = null
    }else if(value=== 'set') {
      this.abortControllerPlunger = new AbortController();
    }else if(this.abortControllerPlunger  && value === 'abort'){
      console.log('abortControllerPlunger was aborted');
      this.abortControllerPlunger.abort()
    }
  }


  colors: any = [
    '#FFC8A5',
    '#F21688',
    '#94ffdf',
    '#90e64e',
    '#FF94B8',
    '#94eaff',
    '#94c1ff',
    '#a894ff',
    '#b14ee6',
    '#94B0C6',
    '#41c4b0',
    '#94E2C6',
    '#a994c6',
    '#94c459',
    '#ffb194',
    '#c494ff',
    '#FF00FF',
    '#00FFFF',
    '#FF69B4',
    '#F0E68C',
    '#DC143C',
    '#00FF00',
    '#D8BFD8',
    '#ADFF2F',
    '#FFD700',
    '#FFA07A',
    '#FF6347',
    '#00FF7F',
    '#FFE4E1',
    '#7B68EE',
    '#6B8E23',
    '#9400D3',
    '#FFB6C1',
    '#FF4500',
    '#FF1493',
    '#8B0000',
    '#BA55D3',
    '#B0E0E6',
    '#2E8B57',
    '#FF8C00',
    '#C71585',
    '#40E0D0',
    '#7FFFD4',
    '#BDB76B',
    '#FA8072',
    '#8A2BE2',
    '#808000',
    '#9932CC',
    '#8B008B',
  ];

  @Action
  async getSignalsForTasq(input): Promise<void> {
    // data = 60
    const {data = 60, isRtd = false, wellNameSearch = '',signals = [],isWellLevel= true} = input
    // this.setSignalController('abort')
    // this.setAxiosSignalController('abort')
    // this.setAxiosSignalController('set')
    // // Create a new AbortController for the new set of requests
    // this.setSignalController('set')
    // const { token } = this.cancelTokenSource;
    this.resetSignals();
    this.setSignalDescriptions([]);
    this.setDidLoadAllSignals(false);
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    const dataCount = 2000;

    let wellName = wellNameSearch

    if(wellNameSearch === ''){
      wellName =
      tasqsListModule.activeTasq?.wellName != null
        ? tasqsListModule.activeTasq?.wellName
        : assetsModule.activeTasq?.wellName;
    }

    console.log(wellName);



    let daysLength = 60;
    const rawData = false;
    if (typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data;
      this.resetSignals();
    }
    this.resetSelectedSignals();



    let selectedSignals: any  = [
      'static pressure',
      'casing pressure',
      'water rate',
      'tubing pressure',
       'flowrate',
      'gas Rate Aggregate',
      'oil Rate Aggregate',
      'target',
      'target band',
      'deferment range',
      'flowrate (predicted v2)',
      'flowrate (predicted)',
      'tank Level'
      ,'sand filter diff pressure',
      'sand filter inlet pressure',
      'inj. flowrate',
      'average amps',
      'drive frequency',
      'drive loading',
      'intake pressure',
      'motor temperature',

    ];

    if(signals.length){
      selectedSignals = signals.map(s => s.toLowerCase())
    }



    let selectedSavedSignals: any = await  userPreferenceDB.getItem('wellSignalsSelected')
    if(selectedSavedSignals && selectedSavedSignals.length && !wellNameSearch){
      if(!wellNameSearch){
        selectedSignals = selectedSavedSignals
      }
    }else{
      await userPreferenceDB.setItem('wellSignalsSelected',selectedSignals)
    }



    let toggleOnSignalsRes:any = await userPreferenceDB.getItem('selected-signal-chart-signals')

    let toggleOnSignals = selectedSignals
    if(toggleOnSignalsRes && toggleOnSignalsRes.length && !wellNameSearch){

      toggleOnSignals =toggleOnSignalsRes.filter(s => selectedSignals.includes(s))
    }else{
      toggleOnSignals = selectedSignals
    }


    if (!wellName) {
      return;
    }
    if (isOnlineApp) {

      let localOperator = getConfigEnv('OPERATOR_PASCALED')


      let backendSignals = []
      if(!signals.length){
        const {data:signalData} =  await axiosClient.post('/meta/asset-signals',{
          operator: localOperator.toLowerCase() == 'pdc' ? 'pdce' : localOperator,
          match_value: wellName,
          match_field: isWellLevel? 'Well': 'Pad',
        })

       if(signalData && signalData.length){

        backendSignals =backendSignals.concat(signalData[0].descriptions)

       }
      }else{
        backendSignals =signals
      }




      // const {
      //   data: { get_clean_ts_data_node_description_combinations_v2: nodeData },
      // }: any = await cleanDataApolloClient.query({
      //   query: getNodeDescriptionCombinations,
      //   variables: {
      //     operator: getConfigEnv('OPERATOR_PASCALED'),
      //     nodeid: wellName,
      //     filter: 'tasq_page',
      //   },
      //   fetchPolicy: 'network-only',
      // });
      // // const backendSignals = jsonParse(nodeData)?.Description || [];
      // // console.log(backendSignals);
      let  signalsToFetch = backendSignals;
      if(!signals.length){
        signalsToFetch.push('TP-LP');
        signalsToFetch.push('CP-LP');
        signalsToFetch.push('CP-TP');
      }


      const removeElements = (arr1, arr2) => {
        const set2 = new Set(arr2); // Convert array2 to a Set for faster lookup
        return arr1.filter(item => !set2.has(item));
    };

    signalsToFetch = removeElements(signalsToFetch, ['GasRate','OilRate','WaterRate']);

      // Just need floats for density, other than that you can use ints

      let fromTime = DateTime.utc()
        .minus({ days: daysLength + tasqsListModule.fromNowBack })
        .toISO()
        .slice(0, -5);
      const toTime = DateTime.utc()
        .minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack })
        .toISO()
        .slice(0, -5);

      fromTime = fromTime.split('T')[0];

      tasqsListModule.setChartFromTime(fromTime);
      tasqsListModule.setChartToTime(toTime);

	  let signals_to_fetch_count = 0;
	  let already_added_index = 0;

    const signalColors = {
      'casing pressure': '#DB94C6',
      'tubing pressure': '#94FFB0',
      'inj. flowrate': '#FFCD5C',
      'flowrate': '#e64e4e',
      'static pressure': '#FFFF5D',
      'tp-lp': '#696969',
      'cp-tp': '#baa79b',
      'cp-lp': '#c2c2c2',
    };



    if(isRtd){
      selectedSignals.push('gas today')
      selectedSignals.push('oil today')
    }



    if(getConfigEnv('OPERATOR_PASCALED').toLowerCase() === 'pdc'){
       localOperator = 'pdce'
    }



    const loadSignals = async (signalsToLoad,intial=false,maxRangeLimit=false) => {
      // Abort any ongoing requests from a previous call to loadSignals
   // Extract the signal from the controller

      await Promise.all(signalsToLoad.map(async (signalName, idx) => {
        try {
          const raw = ['tp-lp', 'cp-lp', 'cp-tp'].includes(signalName.toLowerCase());


          const {data} = await axiosClient.post('/signals/node-data',{
            match: {
              operator: localOperator,
              nodeid: wellName,

            },
            desc: [signalName],
            start_time: fromTime,
            end_time: toTime,
            freq: '30m',
            fillna_method: 'locf',
            gorilla_compress: false,
            raw: true,
            downsample_data: true,
            downsampled_count: maxRangeLimit? 15000 : intial ? 7000: 2000,
          })







          // const {
          //   data: {
          //     get_clean_ts_data_time_range_v2: { dataset, description, units },
          //   },
          // }: any = await cleanDataApolloClient.query({
          //   query: fetchSignalQuery,
          //   variables: {
          //     nodeid: wellName,
          //     description: [signalName],
          //     from_time: fromTime,
          //     to_time: toTime,
          //     raw,
          //     operator: getConfigEnv('OPERATOR_PASCALED'),
          //     gorilla_compress: true,
          //     reg_freq: '5m',
          //     fillna_method: 'locf',
          //   },
          //   fetchPolicy: 'network-only',
          //   context: {
          //     // Pass the abort signal to the fetch options if using fetch as the network interface
          //     fetchOptions: {
          //       signal: signal,
          //     },
          //   },
          // });

          // const parsedDataset = JSON.parse(dataset);
          if (data && data.values && data.values.length) {
            const {description} = data

          if (data.values[0] == undefined) {
            data.values[0] = {
              series: [],
              start: DateTime.now().toISO(),
            };
          } else {
            // console.log(data.values[0]);
            // data.
            // values[0].series = decodeGorillaCompression(
            //   data.values[0].series['$binary']['base64'],
            //   data.values[0].nb_values
            // );
          }


            if(wellNameSearch === ''){
              const currentWellName =
              tasqsListModule.activeTasq?.wellName != null
                ? tasqsListModule.activeTasq?.wellName
                : assetsModule.activeTasq?.wellName;
            const date = new Date();
            date.toISOString();

            if (currentWellName !== wellName) {
              return;
            }
            }


            let  color = ''
            if(signalColors[description[0].toLowerCase()]){
              color = signalColors[description[0].toLowerCase()]
            }else{
              already_added_index += 1;
              this.colors[already_added_index];
            }
              color = signalColors[description[0].toLowerCase()] || this.colors[already_added_index];

            const selected = toggleOnSignals.includes(description[0].toLowerCase()) ? true : false;

            const signalLocal = {
              id: idx,
              name: description[0],
              dataset: data.
              values[0],
              time: data.
              time[0],
              unit: null,
              color,
              wellName,
              daysLength,
              selected,
            };

            this.addSignal(signalLocal);

            signals_to_fetch_count += 1;
            if (signals_to_fetch_count == signalsToFetch.length) {
              this.setDidLoadAllSignals(true);
              this.setSignalController('null')
            }
          }
        }  catch (error) {
          // console.log(`Error: Failed to ${signal} signal data on ${wellName}, for ${daysLength} days`);
          if (error.name === 'AbortError') {
            console.log(`Request for ${signalName} was aborted.`);
          } else {
            console.log(`Error: Failed to load ${signalName} signal data; error: ${error}`);
          }
        }
      }));
    };

    // Load initial signals
    const initialSignals = signalsToFetch.filter((s) => selectedSignals.includes(s.toLowerCase()));
    const secondSignals = signalsToFetch.filter((s) => !selectedSignals.includes(s.toLowerCase()));

    this.setDefaultSignals({type:'defaultSelectedSignals',values:initialSignals})
    const intial = daysLength < 70
    const maxRangeLimit = daysLength < 70 && daysLength > 30
    await loadSignals(initialSignals,intial,maxRangeLimit);

    // Load second signals after initial signals are loaded

    if (secondSignals.length > 0) {
      sleep(2500).then(async () => {
       loadSignals(secondSignals,intial,maxRangeLimit);

      })
    }
    }
  }

  @Action
  async getPlungerSignalsForTasq(data: any = 60): Promise<void> {
    // data = 60
    this.setDidLoadAllPlungerSignals(false);
    this.resetPlungerSignals();
    this.setPlungerSignalDescriptions([]);
    const isOnlineApp = navigator.onLine;
    let isOfflinePreloading = false;
    const dataCount = 2000;
    let wellName =
      tasqsListModule.activeTasq?.wellName != null
        ? tasqsListModule.activeTasq?.wellName
        : assetsModule.activeTasq?.wellName;
    let daysLength = 60;
    const rawData = false;
    if (typeof data === 'object') {
      wellName = data.wellName;
      isOfflinePreloading = true;
      daysLength = data.daysLength;
    } else {
      daysLength = data;
    }

    let localOperator = getConfigEnv('OPERATOR_PASCALED')

    if(getConfigEnv('OPERATOR_PASCALED').toLowerCase() === 'pdc'){

      localOperator = 'pdce'
    }



    this.setAxiosPlungerController('abort')
    // Create a new AbortController for the new set of requests
    this.setAxiosPlungerController('set')
    const { token } = this.cancelTokenPlungerSource;


    this.resetSelectedPlungerSignals();

    const fetchSignalQuery = getCleanTSDataTimeRangeV2;

    if (!wellName) {
      return;
    }
    if (isOnlineApp) {



      const {data:signalData} =  await axiosClient.post('/meta/asset-signals',{
         operator: localOperator,
         match_value: wellName,
         match_field: 'Well',
         signal_filter: ['Plunger']
       })
       let backendSignals = []
      if(signalData && signalData.length){
       backendSignals =backendSignals.concat(signalData[0].descriptions)

      }

      const detailsForEachSignal = backendSignals.map((des, i) => ({
        name: des,
        raw: true,
      }));

      // console.log(backendSignals);

      // 'Arrival Status', 'Open Reason' 'Tubing Length'
      const allowedSignals = [
        'Arrival Duration',
        'Open Duration',
        'Close Duration',
        'Open Casing',
        'Open Tubing',
        'Open Line',
        'Close Reason',
        'Close Flowrate',
        'Close Casing',
        'Close Tubing',
        'Close Line',
        'Cycle Volume',
        'Slug Volume',
        'Arrival Velocity',
        'Open Tbg-Line',
        'Open Csg-Line',
        'Open Csg-Tbg',
        'Close Tbg-Line',
        'Close Csg-Line',
        'Close Csg-Tbg',
        'Afterflow Time',
        'Close/Arrival Ratio',
        'Inj. Flowrate',
        'Plunger Velocity'
      ];
      // console.log(backendSignals);

      let  signalsToFetch = backendSignals
      if(localOperator !== 'petronas'){
        signalsToFetch = backendSignals.filter((signal) => allowedSignals.includes(signal));
      }else{
        signalsToFetch = backendSignals.filter((signal) => signal);
      }


      this.setPlungerSignalDescriptions(signalsToFetch);


      let selectedSignals: any  = [
        'open cp-lp',
        'Open tp-lp',
        'afterflow time',
        'Open Line',
        'open duration',
        'close duration',
        "open cp-tp",
        "afterflow time",
        "open cp-lp",
        "open tp-lp",

      ]

      let selectedSavedSignals: any = await  userPreferenceDB.getItem('plungerSignalsSelected')
      if(selectedSavedSignals && selectedSavedSignals.length){
        selectedSignals = selectedSavedSignals
      }else{
        await userPreferenceDB.setItem('plungerSignalsSelected',selectedSignals)
      }


      let toggleOnSignalsRes:any = await userPreferenceDB.getItem('selected-plungerSignal-chart-signals')

      let toggleOnSignals = selectedSignals
      if(toggleOnSignalsRes && toggleOnSignalsRes.length){
        toggleOnSignals =toggleOnSignalsRes.filter(s => selectedSignals.includes(s))
      }


      selectedSignals = selectedSignals.filter((s) => s);





      let fromTime = DateTime.utc()
        .minus({ days: daysLength + tasqsListModule.fromNowBack })
        .toISO()
        .slice(0, -5);
      const toTime = DateTime.utc()
        .minus({ days: tasqsListModule.fromNowBack === 1 ? 0 : tasqsListModule.fromNowBack })
        .toISO()
        .slice(0, -5);

      const offlineSignals: any = [];
      fromTime = fromTime.split('T')[0];

      tasqsListModule.setChartFromTime(fromTime);
      tasqsListModule.setChartToTime(toTime);

      let signals_to_fetch_count = 0;
      let already_added_index = 0;



      const loadSignals = async (signalsToLoad) => {




        await Promise.all(signalsToLoad.map(async (signalName, idx) => {
          try {
        let raw = false;

        const findRaw = detailsForEachSignal.find((s) => s.name === signalName);

        if (findRaw) {
          raw = findRaw.raw;
        }


        const {data} = await axiosClient.post('/signals/node-data',{
          match: {
            operator: localOperator,
            nodeid: wellName,

          },
          desc: [signalName],
          start_time: fromTime,
          end_time: toTime,
          freq: '30m',
          fillna_method: 'locf',
          gorilla_compress: false,
          raw: true,
          downsample_data: true,
        })

        if (data.values[0] !== undefined) {
          const currentWellName =
            tasqsListModule.activeTasq?.wellName != null
              ? tasqsListModule.activeTasq?.wellName
              : assetsModule.activeTasq?.wellName;
          const date = new Date();
          date.toISOString();

          if (currentWellName !== wellName) {
            return;
          }

          if (data.values[0] == undefined) {
            data.values[0] = {
              series: [],
              start: DateTime.now().toISO(),
            };
          } else {
            // console.log(data.values[0]);
            // data.
            // values[0].series = decodeGorillaCompression(
            //   data.values[0].series['$binary']['base64'],
            //   data.values[0].nb_values
            // );
          }


          let axisName = 'axisY';
          let color = 'pink';
          const description = data.description

          if (description[0].toLowerCase() == 'Arrival Duration'.toLowerCase()) {
            color = '#FF6B6B';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Arrival Velocity'.toLowerCase()) {
            color = '#00FFC2';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Open Duration'.toLowerCase()) {
            color = '#B38ED7';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Close Duration'.toLowerCase()) {
            color = '#854BBF';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Open Casing'.toLowerCase()) {
            color = '#F687FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Tubing'.toLowerCase()) {
            color = '#CEFBCA';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Line'.toLowerCase()) {
            color = '#FFFB9F';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Flowrate'.toLowerCase()) {
            color = '#FF0000';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Close Casing'.toLowerCase()) {
            color = '#EB00FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Tubing'.toLowerCase()) {
            color = '#3AEF2A';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Line'.toLowerCase()) {
            color = '#FFF500';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Cycle Volume'.toLowerCase()) {
            color = '#D9D9D9';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Slug Volume'.toLowerCase()) {
            color = '#FFC0BF';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Open Tbg-Line'.toLowerCase()) {
            color = '#003FFF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Csg-Line'.toLowerCase()) {
            color = '#6087FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open Csg-Tbg'.toLowerCase()) {
            color = '#BFCFFF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Tbg-Line'.toLowerCase()) {
            color = '#FF8000';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Csg-Line'.toLowerCase()) {
            color = '#FFB060';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Close Csg-Tbg'.toLowerCase()) {
            color = '#FFDFBF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open CP-LP'.toLowerCase()) {
            color = '#00F0FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open CP-TP'.toLowerCase()) {
            color = '#9022FF';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Open TP-LP'.toLowerCase()) {
            color = '#00FF19';
            axisName = 'axisY2';
          } else if (description[0].toLowerCase() == 'Afterflow Time'.toLowerCase()) {
            color = '#FF2667';
            axisName = 'axisY';
          } else if (description[0].toLowerCase() == 'Close/Arrival Ratio'.toLowerCase()) {
            color = '#97C2E1';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Close Reason'.toLowerCase()) {
            color = '#97C2E1';
            axisName = 'axisY3';
          } else if (description[0].toLowerCase() == 'Inj. Flowrate'.toLowerCase()) {
            color = '#FFCD5C',
            axisName = 'axisY3';
          }else {
            already_added_index += 1;
            color =  this.colors[already_added_index];
          }

          //   || description[0].toLowerCase() == 'Open tbg-line'
          // || description[0].toLowerCase() == 'Open csg-line'
          // || description[0].toLowerCase() == 'open csg-tbg'
          // || description[0].toLowerCase() == 'close tbg-line'
          // || description[0].toLowerCase() == 'flowrate close'
          // || description[0].toLowerCase() == 'arrival velocity'

          const selected = toggleOnSignals.includes(description[0].toLowerCase()) ? true : false;

          const signalLocal = {
            id: idx,
            name: description[0],
            dataset: data.
            values[0],
            time: data.
            time[0],
            unit: null,
            color,
            axisName,
            wellName,
            daysLength,
            selected,
          };
          if (currentWellName === wellName) {
            this.addPlungerSignal(signalLocal);
          }



          signals_to_fetch_count += 1;
          if (signals_to_fetch_count == signalsToFetch.length) {
            this.setPlungerController('null')
            this.setDidLoadAllPlungerSignals(true);
          }
        }  }  catch (error) {
          // console.log(`Error: Failed to ${signal} signal data on ${wellName}, for ${daysLength} days`);
          if (error.name === 'AbortError') {
            console.log(`Request for ${signalName} was aborted.`);
          } else {
            console.log(`Error: Failed to load ${signalName} signal data; error: ${error}`);
          }
        }


      }));
    };



    const initialSignals = signalsToFetch.filter((s) => selectedSignals.includes(s.toLowerCase()));
    let secondSignals = signalsToFetch.filter((s) => !selectedSignals.includes(s.toLowerCase()));

    this.setDefaultSignals({type:'defaultSelectedPlungerSignals',values:initialSignals})
    await loadSignals(initialSignals)
    if (secondSignals.length > 0) {
      if(this.currentPlungerSignals.length > 1){

        secondSignals = secondSignals.concat([ 'Open CP-LP',
          'Open CP-TP',
          'CP-TP',
          'Open TP-LP',
          'TP-LP'])
      }
      sleep(3000).then(async () => {
       loadSignals(secondSignals);
      })
    }
    }
  }
}

export default getModule(TasqSignalsModule);
